import {
  mapGetters
} from 'vuex'

export default {
  data () {
    return {
      tooltip: {
        textfield: "Wypełnij to pole",
        select: "Wybierz wartość z listy",
        checkbox: "Zaznacz/odznacz opcję",
        scroller: "Przesuń, aby zmienić opcje",
        clearFilter: "Kliknij, aby wyczyścić filtr danych",
        date:"Ustaw parametr raportu"
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['isLogin']),
    isMobile () {
      return !this.$vuetify.breakpoint.mdAndUp;
    }
  }
}