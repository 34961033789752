import {
  VDataTable
} from 'vuetify/lib';
export default {
  name: "BaseVDataTable",
  extends: VDataTable,
  props: {
    mustSort: {
      type:Boolean,
      default:true,
    },
    mobileBreakpoint: {
      type:Number|String,
      default:1025
    }
  },
}

