<template>
  <v-footer :app="!isMobile"
            :fixed="false"
            :absolute="!isMobile"
            color="secondary"
            class="text-caption text-xl-body-1 py-0 theme--light">
    <v-row class="py-1 py-xl-2 my-1 px-10"
           justify="center"
           :style="{'text-align': isMobile ? 'center' : 'none'}"
           align="center">
      <v-col cols="6"
             md="2"
             style="text-align: center;">
        <img :height="isMobile ? 120 : 140"
             src="/img/GUNB_logo_ekran_pion_kolor.png" />
      </v-col>
      <v-col cols="6"
             md="4">
        <div class="primary-txt--text"> Główny Urząd Nadzoru Budowlanego<br>
          ul. Krucza 38/42<br>
          00-926 Warszawa</div>
      </v-col>

      <v-col cols="12"
             class="pa-0"
             md="6">
        <v-row>
          <v-col cols="12"
                 md="4">
            <p class="mb-0 mb-xl-2">
              <router-link class="primary-txt--text"
                           :to="{name:'PrivacyPolicy'}">
                Polityka prywatności
              </router-link>
            </p>
            <p>
              <router-link class="primary-txt--text"
                           :to="{name:'AccessibilityDeclaration'}">
                Deklaracja dostępności
              </router-link>
            </p>
          </v-col>
          <v-col cols="12"
                 md="4">
            <p class="mb-0 mb-xl-2">
              Masz pytania? Napisz na:
            </p>
            <p class="mb-0">
              <a class="primary-txt--text"
                 href="mailto:e-budownictwo@gunb.gov.pl">e-budownictwo@gunb.gov.pl</a>
            </p>
          </v-col>
          <v-col cols="12"
                 md="4">
            <p class="mb-0 mb-xl-2">
              System wykonał:
            </p>
            <p class="mb-0 mb-xl-2">
              <a class="primary-txt--text"
                 target="_blank"
                 href="https://smallgis.pl/">
                SmallGIS Sp z o.o.
              </a>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
const notFixedFooterViews = ['AccountBuildingTitlePage', 'AccountBuildingLogProposalAdd', 'AccountBuildingTitlePageArchival', 'AccountBuildingLogProposalEnter', 'AccountBuildingLogProposalEdit'];
export default {
  name: "TheFooter"
}
</script>