import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./pwa/register-service-worker";
import vuetify from "./plugins/vuetify";
import "./plugins/global-component";
import "./plugins/axios";
import Mixin from './mixins/global-mixin.js'
import "@/scss/index.scss"
import "@/pwa/web-push"
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false;
Vue.mixin(Mixin)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [ process.env.VUE_APP_SENTRY_ORIGIN, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  trackComponents: true,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT
});

Sentry.setTag("app", "edb-client");

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
