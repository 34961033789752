<template>
  <v-app-bar dense
             app
             clipped-left
             height="60">
    <router-link title="Strona startowa Elektronicznego Dziennika Budowy"
                 to="/">
      <img class="pr-3"
           :style="(isMobile ? 'margin-top: 15px; height: 50px;' : 'margin-top: 55px; width: 250px;')"
           alt="Logo EDB"
           src="/img/logo-edb.png" />
    </router-link>

    <template v-slot:extension>
      <v-spacer />
      <v-btn title="Weryfikuj wydruk"
             :large="!isMobile"
             v-if="!isLogin"
             :class="{'black--text': $vuetify.theme.dark, primary: !contrast, 'mr-3': true}"
             :to="{name: 'PdfVerification'}">
        <span class="subtitle-1 text-md-h6 pr-1">Weryfikuj wydruk</span>
      </v-btn>
      <v-btn v-if="!isLogin"
             title="Kliknij, aby zalogować się do Elektronicznego Dziennika Budowy"
             aria-label="Kliknij, aby zalogować się do Elektronicznego Dziennika Budowy"
             :large="!isMobile"
             :class="{'black--text': $vuetify.theme.dark, error: !contrast}"
             @click="login">
        <span class="subtitle-1 text-md-h6 pr-1">Zaloguj</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn v-else
             title="Konto"
             aria-label="Konto"
             :large="!isMobile"
             :class="{'black--text': $vuetify.theme.dark}"
             color="primary"
             :to="{ name: 'AccountUserProfile' }">
        <span class="subtitle-1 text-md-h6 pr-1">{{ displayName }}</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-badge v-if="isLogin"
               :content="String(messages)"
               :value="messages"
               color="#AE1409"
               overlap
               offset-y="25">
        <v-btn title="Powiadomienia"
               aria-label="Powiadomienia"
               color="primary"
               icon
               @click="moveToNotification">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-spacer></v-spacer>

    <v-btn title="Zmień tryb ciemny/jasny"
           aria-label="Zmień tryb ciemny/jasny"
           :x-large="!isMobile"
           icon
           @click="darkMode">
      <v-icon color="accent">mdi-theme-light-dark</v-icon>
    </v-btn>
    <v-btn title="Zmień rozmiar czcionki"
           aria-label="Zmień rozmiar czcionki"
           :x-large="!isMobile"
           icon
           @click="changeTextSize">
      <v-icon color="accent">mdi-format-size</v-icon>
    </v-btn>
    <v-btn title="Włącz/wyłącz tryb wysokiego kontrastu"
           aria-label="Włącz/wyłącz tryb wysokiego kontrastu"
           :x-large="!isMobile"
           icon
           @click="addContrast">
      <v-icon color="accent">mdi-eye</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data () {
    return {
      htmlFontSize: 16,
      contrast: false
    };
  },
  computed: {
    ...mapGetters({
      displayName: "auth/getDisplayName",
      messages: "auth/getMessages",
    }),

    displayName () {
      let userName = this.$store.getters['auth/getDisplayName'];
      if (!this.isMobile)
        return userName;
      else {
        let names = userName.split(" ");
        let twoFirstLetter = "";
        names.forEach(element => {
          twoFirstLetter = twoFirstLetter + element.substring(0, 1) + " ";
        });
        return twoFirstLetter.substring(0, 3);
      }
    }


  },

  methods: {
    login () {
      let props = this.$router.resolve({
        name: "Account",
      });
      let path = window.location.origin.split(/[?#]/)[0];
      if (path.endsWith("/")) path = path.slice(0, -1);
      this.$store.dispatch("auth/login", { redirectUri: path + props.href });
    },
    darkMode () {
      const isDark = localStorage.getItem("edb-dark");
      if (isDark == null || isDark == "false") {
        localStorage.setItem("edb-dark", true);
      } else {
        localStorage.setItem("edb-dark", false);
      }
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (
        this.$store.state.layout.isActiveContast &&
        !this.$vuetify.theme.dark
      ) {
        this.$store.commit("layout/switchActiveContrast");
        document.querySelector("body").classList.toggle("contrast");
      }
      this.contrast = false;
    },
    addContrast () {
      document.querySelector("body").classList.toggle("contrast");
      this.$store.commit("layout/switchActiveContrast");
      this.$vuetify.theme.dark = this.$store.state.layout.isActiveContast;
      this.contrast = !this.contrast;
    },
    changeTextSize () {
      let fontSize = this.htmlFontSize > 20 ? 16 : ++this.htmlFontSize;
      this.htmlFontSize = fontSize;
      document.getElementsByTagName("html")[0].style.fontSize = fontSize + "px";
    },

    moveToNotification () {
      this.$router.push({ name: "AccountNotification" }).catch(() => { });
    }

  },
  mounted () {
    const isDark = localStorage.getItem("edb-dark");
    if (
      isDark == "true" ||
      (window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    )
      this.$vuetify.theme.dark = true;
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  z-index: 1;
}
</style>