<template>
  <div class="text-center">
    <v-snackbar v-model="snackbar"
                class="smaller-snackbar"
                color="white"
                timeout=-1>
      <v-list subheader
              color="white">
        <v-subheader color="black">Powiadomienia</v-subheader>
        <v-list-item link
                     color="black"
                     @click="moveToNotification(item)"
                     v-for="item in items"
                     :key="item.messageId">
          <v-list-item-content color="black">
            <v-row>
              <v-col cols="10">
                <v-list-item-title class="text-wrap"
                                   color="black"
                                   :title="item.details">{{ item.title + ' (' + moment(item.sentDate).format('YYYY-MM-DD HH:mm') + ')' }}</v-list-item-title>
              </v-col>
              <v-col cols="2"
                     class="pa-0">
                <v-btn title="Usuń"
                       aria-label="Usuń"
                       color="black"
                       text
                       @click="removeItem(item)">
                  <v-list-item-icon>
                    <v-icon> mdi-close </v-icon>
                  </v-list-item-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

      </v-list>
      <v-row justify="end"
             class="ma-2 mb-2 mb-md-2">
        <v-btn text
               color="black"
               @click="snackbar = false">Zamknij</v-btn>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data: () => ({
    snackbar: false,
    items: [],
  }),
  methods: {
    async removeItem (item) {
      this.items = this.items.filter((x) => x.messageId != item.messageId);
      this.$axios.put("/notification/receiving", item).then((response) => {
      }).catch(e => {
        this.$store.commit("layout/showErrorDialog", "Nie udało się zapisać danych")
      });
      if (this.items.length < 1) {
        this.snackbar = false;
      }
    },
    async moveToNotification (noty) {
      let id = noty.messageId
      let path = this.$route.path
      const navigationResult = await this.$router.push({ name: "AccountNotification", query: { id } });

      this.snackbar = false
    },
    moment
  },
  async created () {
    let { data } = await this.$axios.get("/notification/news");
    this.items = data;

    if (data.length > 0) this.snackbar = true;
    else this.snackbar = false;
  },
};
</script>
<style scoped>
.v-list-item__content {
  color: #000000 !important;
}
.theme--dark.v-subheader {
  color: #000000 !important;
}
.v-list-item__title {
  color: #000000 !important;
}
.v-subheader {
  font-size: 1.4rem !important;
}
</style>