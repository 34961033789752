import {
  axios
} from "@/util/index.js";
import Cookies from "js-cookie";
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import Utf8 from 'crypto-js/enc-utf8';
import {
  getParamFromUrl
} from "@/util/auth-util"

var createUser = (access_token, refresh_token) => {
  let data = Utf8.stringify(Base64.parse(access_token.split(".")[1].replace(/_/g, '/').replace(/-/g, '+')));
  let user = JSON.parse(data);
  user.refresh_token = refresh_token;
  user.access_token = access_token;

  return user;

};

export {
  createUser
}

export default {
  namespaced: true,
  state: {
    user: null,
    accessToken: null,
    messages: 0
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
      if (user)
        sessionStorage.setItem('user', JSON.stringify(user));
      else
        sessionStorage.removeItem('user');
    },
    updateToken (state, token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      state.user.access_token = token;
      sessionStorage.setItem('user', JSON.stringify(state.user));
      state.accesToken = token;
    },
    addRole (state, roleName) {
      if (state.user && state.user.authorities) {
        state.user.authorities.push(roleName);
      }
    },
    setMessages (state, messages) {
      state.messages = messages;
    },
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken;
    },
  },
  actions: {
    refreshUser ({
      state,
      commit
    }) {
      let user = state.user;
      commit("setUser", user);
    },

    login ({ }, params) {
      let defaultParams = {
        redirectUri: document.location.href
      };
      let p = Object.assign(defaultParams, params);

      function getRandomString (length) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length; i++) {
          result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
      }
      let VERIFIER = getRandomString(32);
      let CODE_CHALLENGE = Base64.stringify(Utf8.parse(sha256(Utf8.parse(VERIFIER))));

      Cookies.set("edb_v", VERIFIER, {
        path: '/'
      });
      Cookies.set("edb_r_v", p.redirectUri, {
        path: '/'
      });
      window.location.href =
        `${process.env.VUE_APP_AUTH_SERVICE_URL}/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(p.redirectUri)}&code_challenge=${CODE_CHALLENGE}&code_challenge_method=S256&scope=read`;
    },

    resolveCode ({
      commit
    }, code) {
      let codeVerifier = Cookies.get("edb_v");
      let redirect_url = Cookies.get("edb_r_v");
      let body = new FormData();
      body.append("redirect_uri", redirect_url);
      body.append("client_id", process.env.VUE_APP_CLIENT_ID);
      body.append("code", code);
      body.append("grant_type", 'authorization_code');
      body.append("code_verifier", codeVerifier);
      return axios.post(`${process.env.VUE_APP_AUTH_SERVICE_URL}/oauth/token`,
        body, {
        "Content-Type": "multipart/form-data"
      },



      ).then(({
        data
      }) => {
        let user = createUser(data.access_token, data.refresh_token);
        commit("setUser", user);
        return true;
      })
    },

    tryLogin ({
      commit
    }, accesToken) {
      let user = createUser(accesToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${accesToken}`;
      commit("setUser", user);
      commit("setAccessToken", accesToken);
      return Promise.resolve(!!user);
    },
    logout ({
      dispatch
    }) {
      dispatch("removeSession")
      let orgin = location.protocol + "//" + location.host;
      window.location.href = `${process.env.VUE_APP_AUTH_SERVICE_URL
        }/logout?redirect_uri=${orgin}`;
    },
    wklogout ({
      dispatch,state
    }) {
      let {nId,sId}=state.user;
      dispatch("removeSession")
      let orgin = location.protocol + "//" + location.host;
      window.location.href = `${process.env.VUE_APP_AUTH_SERVICE_URL}/wk-logout?redirect_uri=${orgin}&nId=${nId}&sId=${sId}`;
    },
    
    removeSession ({
      commit
    }) {
      commit("setUser", null);
      commit("setAccessToken", null);
      delete axios.defaults.headers.common["Authorization"];
      return true;
    },
    init ({
      commit,
      dispatch,
      state
    }) {
      let user = sessionStorage.getItem('user');
      if (user) {
        let parseUser = JSON.parse(user);
        let exp = parseUser.exp;
        let expiration = new Date(exp * 1000);
        let isExpired = expiration < new Date();
        if (isExpired === true)
          return dispatch("removeSession").then(x => {
            return false;
          });
        commit("setUser", parseUser);
        axios.defaults.headers.common["Authorization"] = `Bearer ${state.user.access_token}`;
        commit("setAccessToken", state.user.access_token);

        return Promise.resolve(true)

      } else {
        let code = getParamFromUrl("code", window.location.href);
        if (code) {
          return dispatch("resolveCode", code).then(r => {
            if (r)
              axios.defaults.headers.common["Authorization"] = `Bearer ${state.user.access_token}`;
            commit("setAccessToken", state.user.access_token);

            dispatch("conf/audit", {
              type: "user_login"
            }, {
              root: true
            });

            return r;
          });
        }
        return Promise.resolve(false);
      }
    }
  },
  getters: {
    getDisplayName: state => {
      if (!state.user) return null;
      return !!state.user.name ? state.user.name : `${state.user.firstName || ""} ${state.user.lastName || ""}`
    },
    getUserId: state => {
      if (!state.user) return null;
      return state.user.id;
    },
    getProvider: state => {
      if (!state.user) return null;
      return state.user.provider;
    },
    isLogin: state => {
      return !!state.accessToken;
    },
    getMessages: state => {
      if (!state) return null;
      return state.messages;
    },
    getPermissions: state => {
      return state.user && state.user.authorities;
    },
    hasPermission: (state) => (permission) => {
      return state.user && state.user.authorities && state.user.authorities.includes(permission);
    },
    hasAtLeastOnePermission: (state) => (permissions) => {
      if (state.user && state.user.authorities) {
        if (permissions) {
          let result = false;
          permissions.forEach(x => {
            if (x === undefined || x === null || x === true || state.user.authorities.includes(x)) {
              result = true;
            }
          })
          return result;
        }
        return true;
      }
      return false;
    },
  }
};