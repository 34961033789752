const defaultDialog = {
    show: false,
    text: null,
    title: null,
};
const defaultAskDialog = {
    show: false,
    text: null,
    title: 'Pytanie',
    yesClick: null,
    yesLabel: 'TAK',
    noLabel: 'NIE'
};
export default {
    namespaced: true,
    state: {
        isActiveContast: false,
        overlay: false,
        dialog: {
            ...defaultDialog
        },
        askDialog: {
            ...defaultAskDialog
        },
        proposalListFilters: {
            filters: null,
            options: null
        },
        navigationDrawerVisibility: true,
        onLine: true,
    },
    mutations: {
        switchActiveContrast (state) {
            state.isActiveContast = !state.isActiveContast;
        },
        showOverlay (state) {
            state.overlay = true;
        },
        hideOverlay (state) {
            state.overlay = false;
        },
        showInfoDialog (state, payload) {
            state.dialog.title = "Informacja";
            state.dialog.text = payload;
            state.dialog.show = true;
        },
        showInfoDialogTitle (state, payload, title) {
            state.dialog.title = "Podsumowanie importu";
            state.dialog.text = payload;
            state.dialog.show = true;
        },
        showErrorDialog (state, payload) {
            state.dialog.title = "Uwaga";
            state.dialog.text = payload;
            state.dialog.show = true;
        },
        hideDialog (state) {
            state.dialog = {
                ...defaultDialog
            };
        },
        showAskDialog (state, payload) {
            let mergePayload = Object.assign({}, defaultAskDialog, payload);
            state.askDialog = mergePayload;
            state.askDialog.yesClick = () => {
                payload.yesClick(payload.data);
                state.askDialog = {
                    ...defaultAskDialog
                }
            };
            state.askDialog.show = true;
        },
        hideAskDialog (state) {
            state.askDialog = {
                ...defaultAskDialog
            };
        },
        setProposalListFilters (state, payload) {
            state.proposalListFilters = payload;
        },
        setNavigationDrawerVisibility (state, visibility) {
            state.navigationDrawerVisibility = visibility;
        },
        setOnLine (state, online) {
            state.onLine = online;
        },
    },
    getters: {
        getOverlay (state) {
            return state.overlay;
        },
        getDialog (state) {
            return state.dialog;
        },
        getAskDialog (state) {
            return state.askDialog;
        },
        getProposalListFilters (state) {
            return state.proposalListFilters;
        },
        getNavigationDrawerVisibility (state) {
            return state.navigationDrawerVisibility;
        },
        getOnLine (state) {
            return state.onLine;
        },
    }
}