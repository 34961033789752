import ax from "axios";
import {
    stringify
} from "qs";

var axios = ax.create({
    baseURL: process.env.VUE_APP_API_URL,
    paramsSerializer: function (params) {
        return stringify(params, {
            arrayFormat: 'comma'
        })
    },
})
//axios.defaults.headers = ax.defaults.headers;
export {
    axios
};

export * from "./auth-util";